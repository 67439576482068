<template>
  <div>
    <b-form inline @submit.prevent="query">
      <div class="text-left w-25 p-1">
        <label class="d-block">{{ $t('SEARCH_FROM_DATE_LABEL') | capitalize }}</label>
        <b-datepicker :state="state('from')" v-model="$v.form.from.$model"></b-datepicker>
      </div>

      <div class="text-left w-25 p-1">
        <label class="d-block">{{ $t('SEARCH_TO_DATE_LABEL') | capitalize }}</label>
        <b-datepicker :state="state('to')" v-model="$v.form.to.$model"></b-datepicker>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{ $t('COMPANY_FILTER_LABEL') | capitalize }}</label>
        <company-single-selector :state="state('companyId')" @instance="setCompany" class="w-100 mb-2"
                                 v-model="$v.form.companyId.$model" value-type="id"
                                 :undefined-means="$t('ALL_COMPANIES_LABEL') | capitalize"></company-single-selector>
      </div>

      <div class="w-25 p-1" v-if="company">
        <label class="d-block">{{ $t('TURN_FILTER_LABEL') | capitalize }}</label>
        <turn-single-selector :company-id="company.id" class="w-100 mb-2" v-model="$v.form.turnId.$model"
                              value-type="id"
                              :undefined-means="$t('ALL_TURNS_LABEL') | capitalize"></turn-single-selector>
      </div>

      <div class="w-25 p-1">
        <label class="d-block">{{ $t('TRAVEL_TYPE_FILTER_LABEL') | capitalize }}</label>
        <travel-type-single-selector class="w-100 mb-2" v-model="$v.form.travelTypeId.$model" value-type="id"
                                     :undefined-means="$t('ALL_TRAVEL_TYPES_LABEL') | capitalize"></travel-type-single-selector>
      </div>

      <b-button type="submit" variant="primary">
        <b-icon-circle-fill animation="throb" v-if="loading"></b-icon-circle-fill>
        <b-icon-search v-else></b-icon-search>
        {{ $t('QUERY_LABEL') | capitalize }}
      </b-button>
    </b-form>

    <div v-if="data && data.length > 0" class="mt-2">
      <h1 class="text-center">
        {{ $t('COLLECT_REPORT_TITLE', {general: company ? company.name : $t('PAYROLL_REPORT_TITLE_GENERAL')}) }}</h1>
      <h2 class="text-center">{{ $t('SEARCH_FROM_DATE_LABEL') }} {{ form.from | date }} {{ $t('SEARCH_TO_DATE_LABEL') }}
        {{ form.to | date }}</h2>

      <div v-for="travelType in travelTypes" :key="travelType.travelType">
        <h2>{{ travelType.travelType }}</h2>
        <hr />
        <b-table-simple sticky-header v-for="turn in travelType.turns" :key="travelType.travelType+turn" hover>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th sticky-column class="text-center">
                {{ turn }}
              </b-th>
              <b-th colspan="15"></b-th>
            </b-tr>
            <b-tr>
              <b-th>{{ $t('PAYROLL_REPORT_ROUTE_LABEL') | capitalize }}</b-th>
              <b-th sticky-column colspan="2" class="text-center">{{ $t('PAYROLL_REPORT_MONDAY_LABEL') | capitalize }}</b-th>
              <b-th sticky-column colspan="2" class="text-center">{{ $t('PAYROLL_REPORT_TUESDAY_LABEL') | capitalize }}</b-th>
              <b-th sticky-column colspan="2" class="text-center">{{ $t('PAYROLL_REPORT_WEDNESDAY_LABEL') | capitalize }}</b-th>
              <b-th sticky-column colspan="2" class="text-center">{{ $t('PAYROLL_REPORT_THURSDAY_LABEL') | capitalize }}</b-th>
              <b-th sticky-column colspan="2" class="text-center">{{ $t('PAYROLL_REPORT_FRIDAY_LABEL') | capitalize }}</b-th>
              <b-th sticky-column colspan="2" class="text-center">{{ $t('PAYROLL_REPORT_SATURDAY_LABEL') | capitalize }}</b-th>
              <b-th sticky-column colspan="2" class="text-center">{{ $t('PAYROLL_REPORT_SUNDAY_LABEL') | capitalize }}</b-th>
              <b-th sticky-column>{{ $t('PAYROLL_REPORT_TOTAL_LABEL') | capitalize }}</b-th>
            </b-tr>
            <b-tr>
              <b-th></b-th>
              <b-th class="text-center">E</b-th>
              <b-th class="text-center">S</b-th>
              <b-th class="text-center">E</b-th>
              <b-th class="text-center">S</b-th>
              <b-th class="text-center">E</b-th>
              <b-th class="text-center">S</b-th>
              <b-th class="text-center">E</b-th>
              <b-th class="text-center">S</b-th>
              <b-th class="text-center">E</b-th>
              <b-th class="text-center">S</b-th>
              <b-th class="text-center">E</b-th>
              <b-th class="text-center">S</b-th>
              <b-th class="text-center">E</b-th>
              <b-th class="text-center">S</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="row in getTravels(travelType, turn)" :key="row.companyId">
              <b-td>{{ row.route }}</b-td>
              <b-td class="text-center table-success">{{ row.monday0 }}</b-td>
              <b-td class="text-center table-success">{{ row.monday1 }}</b-td>
              <b-td class="text-center">{{ row.tuesday0 }}</b-td>
              <b-td class="text-center">{{ row.tuesday1 }}</b-td>
              <b-td class="text-center table-success">{{ row.wednesday0 }}</b-td>
              <b-td class="text-center table-success">{{ row.wednesday1 }}</b-td>
              <b-td class="text-center">{{ row.thursday0 }}</b-td>
              <b-td class="text-center">{{ row.thursday1 }}</b-td>
              <b-td class="text-center table-success">{{ row.friday0 }}</b-td>
              <b-td class="text-center table-success">{{ row.friday1 }}</b-td>
              <b-td class="text-center">{{ row.saturday0 }}</b-td>
              <b-td class="text-center">{{ row.saturday1 }}</b-td>
              <b-td class="text-center table-success">{{ row.sunday0 }}</b-td>
              <b-td class="text-center table-success">{{ row.sunday1 }}</b-td>
              <b-td>{{ row.total }}</b-td>
            </b-tr>
            <b-tr>
              <b-td></b-td>
              <b-td class="text-center">{{ getSum('monday', travelType, turn, 0) }}</b-td>
              <b-td class="text-center">{{ getSum('monday', travelType, turn, 1) }}</b-td>
              <b-td class="text-center">{{ getSum('tuesday', travelType, turn, 0) }}</b-td>
              <b-td class="text-center">{{ getSum('tuesday', travelType, turn, 1) }}</b-td>
              <b-td class="text-center">{{ getSum('wednesday', travelType, turn, 0) }}</b-td>
              <b-td class="text-center">{{ getSum('wednesday', travelType, turn, 1) }}</b-td>
              <b-td class="text-center">{{ getSum('thursday', travelType, turn, 0) }}</b-td>
              <b-td class="text-center">{{ getSum('thursday', travelType, turn, 1) }}</b-td>
              <b-td class="text-center">{{ getSum('friday', travelType, turn, 0) }}</b-td>
              <b-td class="text-center">{{ getSum('friday', travelType, turn, 1) }}</b-td>
              <b-td class="text-center">{{ getSum('saturday', travelType, turn, 0) }}</b-td>
              <b-td class="text-center">{{ getSum('saturday', travelType, turn, 1) }}</b-td>
              <b-td class="text-center">{{ getSum('sunday', travelType, turn, 0) }}</b-td>
              <b-td class="text-center">{{ getSum('sunday', travelType, turn, 1) }}</b-td>
              <b-td class="text-right">{{ $t('PAYROLL_REPORT_TOTAL_LABEL') | capitalize }}</b-td>
              <b-td>{{ getTravelsTotal(travelType, turn) }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>

        <b-table-simple hover caption-top>
          <caption class="bg-dark pl-1">{{ $t('TOTAL_BY_TRAVEL_TYPE', travelType) | capitalize }}</caption>
          <b-thead>
            <b-tr>
              <b-th>{{ $t('PAYROLL_REPORT_ROUTE_LABEL') | capitalize }}</b-th>
              <b-th class="text-center">{{ $t('PAYROLL_REPORT_MONDAY_LABEL') | capitalize }}</b-th>
              <b-th class="text-center">{{ $t('PAYROLL_REPORT_TUESDAY_LABEL') | capitalize }}</b-th>
              <b-th class="text-center">{{ $t('PAYROLL_REPORT_WEDNESDAY_LABEL') | capitalize }}</b-th>
              <b-th class="text-center">{{ $t('PAYROLL_REPORT_THURSDAY_LABEL') | capitalize }}</b-th>
              <b-th class="text-center">{{ $t('PAYROLL_REPORT_FRIDAY_LABEL') | capitalize }}</b-th>
              <b-th class="text-center">{{ $t('PAYROLL_REPORT_SATURDAY_LABEL') | capitalize }}</b-th>
              <b-th class="text-center">{{ $t('PAYROLL_REPORT_SUNDAY_LABEL') | capitalize }}</b-th>
              <b-th>{{ $t('PAYROLL_REPORT_TOTAL_LABEL') | capitalize }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td></b-td>
              <b-td class="text-center">{{ getSum('monday', travelType) }}</b-td>
              <b-td class="text-center">{{ getSum('tuesday', travelType) }}</b-td>
              <b-td class="text-center">{{ getSum('wednesday', travelType) }}</b-td>
              <b-td class="text-center">{{ getSum('thursday', travelType) }}</b-td>
              <b-td class="text-center">{{ getSum('friday', travelType) }}</b-td>
              <b-td class="text-center">{{ getSum('saturday', travelType) }}</b-td>
              <b-td class="text-center">{{ getSum('sunday', travelType) }}</b-td>
              <b-td class="text-right">{{ $t('PAYROLL_REPORT_TOTAL_LABEL') | capitalize }}</b-td>
              <b-td>{{ getTravelsTotal(travelType) }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>


      <div class="w-100">
        <div class="w-75 d-inline-block text-right">
          <h3>{{ $t('PAYROLL_TOTAL_LABEL') | capitalize }}</h3>
        </div>
        <div class="w-25 d-inline-block text-right">
          <h3>{{ travelSum }}</h3>
        </div>
      </div>
    </div>
    <div v-else>
      <h1 class="text-center">{{ $t('REPORTS_NO_DATA') | capitalize }}</h1>
    </div>
  </div>

</template>

<script>
import {CapitalFilter, DateFilter, Form, SendsMessages} from "@/mixins";
import {required} from "vuelidate/lib/validators";
import Reports from "@/reports/index";
import moment from "moment";
import CompanySingleSelector from "@/companies/CompanySingleSelector";
import TurnSingleSelector from "@/turns/TurnSingleSelector";
import TravelTypeSingleSelector from "@/travel-types/TravelTypeSingleSelector";

export default {
  name: "CollectReport",
  components: {TravelTypeSingleSelector, TurnSingleSelector, CompanySingleSelector},
  mixins: [CapitalFilter, SendsMessages, Form, DateFilter],
  data() {
    return {
      loading: false,
      data: [],
      travelTypes: [],
      company: null,
      form: {
        from: new Date(),
        to: new Date(),
        companyId: undefined,
        turnId: undefined,
        travelTypeId: undefined,
        travelSum: 0,
      }
    }
  },
  methods: {
    getSum(day, travelType, turn, inOut = 3) {
      return this.data.filter(x => x.travelType === travelType.travelType && (!turn || x.turn === turn)).map(x => inOut !== 3 ? +x[day + '' + inOut] : +x[day + '0'] + +x[day + '1']).reduce((a, b) => a + b);
    },
    setCompany(company) {
      this.company = company;
    },
    getTravels(travelType, turn) {
      console.log('get travels')
      console.log(travelType)
      return this.data.filter(x => x.travelType === travelType.travelType && (!turn || x.turn === turn));
    },
    getTravelsTotal(travelType, turn) {
      console.log('get totals')
      console.log(travelType)
      return this.data.filter(x => x.travelType === travelType.travelType && (!turn || x.turn === turn)).map(x => x.total).reduce((a, b) => a + b);
    },
    async query() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return;
        }
        this.loading = true;
        this.data = [];
        this.travelSum = 0;
        const resp = await Reports.collection(this.form);
        this.data = resp.data;
        this.travelSum = resp.data.map(x => x.total).reduce((a, b) => a + b);
        const travelTypes = new Set(resp.data.map(x => x.travelType));
        this.travelTypes = [];
        travelTypes.forEach(tt => {
          const ttt = resp.data.filter(x => x.travelType === tt).map(x => x.turn)
          this.travelTypes.push({travelType: tt, turns: new Set(ttt)});
        })
        console.log('travel types')
        console.log(this.travelTypes);
      } catch (e) {
        this.sendError('ERROR_LOADING_REPORT_COLLECTION', {}, e);
      } finally {
        this.loading = false;
      }
    }
  },
  validations: {
    form: {
      from: {required},
      to: {
        required, min: (value, vm) => {
          return moment(value).toDate() >= moment(vm.from).toDate();
        }
      },
      companyId: {required},
      turnId: {},
      travelTypeId: {}
    }
  }
}
</script>

<style scoped>
.b-table-sticky-header {
  max-height: 800px !important;
  height: auto !important;
}
</style>